//0px!important: 0px !important;
//0px!important: 28px;
//50%!important: 50% !important;
//0px!important: 28px !important;
:root {
    //RADIUSS 1
    --f7-card-content-padding-horizontal: 12px !important;
    --f7-card-content-padding-vertical: 12px !important;
    --f7-segmented-strong-padding: 0px !important;
    --f7-segmented-strong-bg-color: var(--f7-theme-color-tint) !important;
    --f7-segmented-strong-button-active-bg-color: var(--f7-theme-color-shade) !important;
    --f7-segmented-strong-button-text-transform: capitalize !important;
    --f7-message-text-footer-font-size: 10px !important;
    --f7-message-text-header-font-size: 10px !important;
    --f7-messagebar-textarea-height: 35px !important;
    --f7-navbar-large-title-font-size: 18px !important;
    --f7-navbar-large-title-height: 30px !important;
    --f7-chip-height: 14px;
    --f7-chip-height: 20px;
    --f7-chip-padding-horizontal: 5px;
    --f7-table-body-cell-height: 20px;
    --f7-table-head-cell-height: 22px;
    --f7-font-size: 11px !important;
    --f7-font-family: Arial,sans-serif;
    --f7-table-body-font-size: 13px !important;
    --f7-list-item-media-margin: 4px !important;
    --f7-list-item-min-height: 51px !important;
    --f7-navbar-height: 34px !important;
    --f7-appbar-height: 34px !important;
    --f7-searchbar-input-border-radius: 0px !important;
    --f7-actions-border-radius: 0px !important;
    --f7-range-bar-border-radius: 0px !important;
    --f7-notification-border-radius: 0px !important;
    --f7-dialog-input-border-radius: 0px !important;
    --f7-messagebar-textarea-border-radius: 0px !important;
    --f7-popup-push-border-radius: 0px !important;
    --f7-popup-border-radius: 0px !important;
    --f7-progressbar-border-radius: 0px !important;
    --f7-sheet-push-border-radius: 0px !important;
    //RADIUSS 2
    --f7-block-inset-border-radius: 0px !important;
    --f7-button-border-radius: 0px !important;
    --f7-calendar-modal-border-radius: 0px !important;
    --f7-card-border-radius: 0px !important;
    --f7-card-expandable-tablet-border-radius: 0px !important;
    --f7-dialog-border-radius: 0px !important;
    --f7-fab-label-border-radius: 0px !important;
    --f7-input-outline-border-radius: 0px !important;
    --f7-list-inset-border-radius: 0px !important;
    --f7-searchbar-in-page-content-border-radius: 0px !important;
    --f7-searchbar-in-page-content-input-border-radius: 0px !important;
    --f7-messagebar-attachment-border-radius: 0px !important;
    --f7-message-bubble-border-radius: 0px !important;
    --f7-popover-border-radius: 0px !important;
    --f7-preloader-modal-border-radius: 0px !important;
    --f7-stepper-border-radius: 0px !important;
    --f7-timeline-item-inner-border-radius: 0px !important;
    --f7-toast-border-radius: 0px !important;
    --f7-tooltip-border-radius: 0px !important;
    //RADIUSS3
    --f7-message-avatar-border-radius: 50% !important;
    --f7-radio-border-radius: 50% !important;
    --f7-range-label-border-radius: 50% !important;
    //RADIUSS4
    --f7-card-expandable-border-radius: 0px !important;
    --f7-checkbox-border-radius: 0px !important;
    --f7-menu-item-border-radius: 0px !important;
}