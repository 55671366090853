@font-face {
	font-family: Material Icons;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Material Icons"), local("MaterialIcons-Regular"),
		url("./css/MaterialIcons-Regular.woff2") format("woff2"),
		url("./css/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
	font-family: Material Icons;
	font-weight: 400;
	font-style: normal;
	font-size: 24px;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: "liga";
}

.md {
	--f7-navbar-shadow-image: none;
}

* {
	box-sizing: content-box;
}

.ptr-ignore {
	height: 100%;
	overflow-y: auto;
	padding: 0px 16px;
}

.list li.list-group-title,
li.list-group-title {
	height: 25px;
}

.md label.item-radio:not(.item-radio-icon-end) > .icon-radio {
	margin-right: 8px;
}

.swipeout-actions-right {
	right: 6px;
}

.item-input-invalid:after {
	background-color: red !important;
}

.md .navbar a.link {
	padding: 0px 6px;
}

.md .navbar a.icon-only {
	width: auto;
}

.md .input-with-error-message,
.md .input-with-info,
.md .item-input-with-error-message,
.md .item-input-with-info {
	padding-bottom: 0px;
}

.md .input-error-message,
.md .input-info,
.md .item-input-error-message,
.md .item-input-info {
	position: absolute;
	top: -6px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	right: 0;
	width: auto;
	left: auto;
	margin-top: 0px;
}

.inputInaltimeNoua .item-inner,
.inputInaltimeNoua .item-content {
	min-height: 36px;
	height: 36px;
}

inputInaltimeNoua:hover {
	background-color: rgba(20, 20, 20, 0.08);
}

.inputInaltimeNoua:after {
	content: "";
	position: absolute;
	background-color: var(--f7-list-item-border-color);
	display: block;
	z-index: 15;
	top: auto;
	right: auto;
	bottom: 0;
	left: 0;
	height: 1px;
	width: 100%;
}

.searchbar input[type="search"],
.searchbar input[type="text"] {
	padding-left: 38px;
	padding-right: 28px;
}

.list .smart-select .item-content:after {
	content: "";
	position: absolute;
	background-color: var(--f7-list-item-border-color);
	display: block;
	z-index: 15;
	top: auto;
	right: auto;
	bottom: 0;
	left: 0;
	height: 1px;
	width: 100%;
}

.popover-inner .list .item-content :after {
	display: none;
}

.smart-select-popover .popover-inner {
	max-height: 50vh;
}

.popoverulMeu {
	border-radius: 0px 0px 16px 16px;
	left: 0px !important;
}

.popoverulMeu .popover-inner {
	max-height: 500px;
}

.list .item-checkbox:after {
	content: "";
	position: absolute;
	background-color: var(--f7-list-item-border-color);
	display: block;
	z-index: 15;
	top: auto;
	right: auto;
	bottom: 0;
	left: 0;
	height: 1px;
	width: 100%;
	transform-origin: 50% 100%;
	transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
}

.view,
.views {
	overflow: unset;
}

.themeBackground {
	overflow: auto;
	text-align: center;
	background-color: var(--f7-page-bg-color);
}

.list {
	ul:before {
		height: 0px;
	}

	ul:after {
		height: 0px;
	}
}

.view-main {
	z-index: 1000;
}

.treeview-item-root.treeview-item-selectable {
	padding: 0px;
}

.data-table tbody td,
.data-table tbody th {
	white-space: nowrap;
}

.treeview-item {
	padding: 8px 0px 8px 0px;
}

.ptr2 {
	background-color: var(--f7-page-bg-color);
	border-radius: 0px 0px 0px 0px;
	padding: 16px 0px;
	display: inline-block;
	height: calc(100% - 32px);
	width: 100%;
}

.ptr3 {
	background-color: var(--f7-page-bg-color);
	border-radius: 0px 0px 0px 0px;
	height: 100%;
	width: 100%;
}

.navbar-bg {
	background-color: var(
		--f7-button-outline-border-color,
		var(--f7-theme-color)
	) !important;
}

.chip-media {
	padding-top: 5px;
	background-color: var(--f7-theme-color) !important;
}

.chip {
	color: #e6e6e6;
	border-radius: 0px;
	// text-decoration: underline;
	border-color: var(--f7-theme-color) !important;
}

.navbar {
	.title {
		position: absolute;
		margin: 0px;
		text-align: center;
		left: 50%;
		transform: translateX(-50%);
	}
}

// @media print {
// 	body * {
// 		visibility: hidden;
// 	}

// 	#section-to-print,
// 	#section-to-print * {
// 		visibility: visible;
// 	}

// 	#section-to-print {
// 		position: absolute;
// 		left: 0;
// 		top: 0;
// 	}

// 	#reparatiesipredare {
// 		display: none;
// 	}

// 	#divRating {
// 		display: none;
// 	}

// 	#divTimeline {
// 		display: none;
// 	}
// }

// SCSS
.recharts-wrapper,
.recharts-surface {
	@media print {
		width: 100% !important;
		height: 100% !important;
	}
}

@media print {
	body * {
		visibility: hidden;
	}

	.pageColored {
		visibility: hidden;
	}
	.section-to-print {
		visibility: visible !important;
		background-color: white;
		height: 100%;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		margin: 0;
		padding: 15px;
		font-size: 14px;
		line-height: 18px;
	}

	#section-to-print,
	#section-to-print * {
		visibility: visible !important;
	}

	#section-to-print {
		position: absolute;
		left: 0;
		top: 0;
	}
}

i.icon {
	margin: 5px;
}

/* width */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0px 0px 0px darkgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: darkgray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--f7-theme-color);
}

.tabs .tab {
	padding-top: 0px;
}

.setareLocatie {
	border-radius: 20px 20px 0px 0px;
}

.login-screen .page,
.login-screen-content,
.login-screen-page {
	background: var(--f7-page-bg-color) !important;
}

.searchbar {
	overflow: hidden;
}

.searchbar-found ul:first-of-type {
	display: none !important;
}

:root {
	--f7-dialog-text-align: center !important;
	--f7-button-height: 40px !important;
	--f7-treeview-children-offset: 10px !important;
}

.md .dialog-buttons-vertical .dialog-button {
	height: 38px !important;
	line-height: 38px !important;
}

.col-center-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.calendar .toolbar {
	background-color: var(--f7-theme-color);
}

.card-footer a.link i.icon,
.card-header a.link i.icon {
	margin-top: 0px;
}

.pg-viewer {
	overflow: hidden;
}

.pdf-canvas canvas {
	height: 100vh;
	width: 80vh;
	transform: rotate(-90deg);
	display: block;
}

.pdf-canvas canvas ~ canvas {
	display: none;
}

#docx {
	height: 100vh;
	width: 80vh;
	transform: rotate(-90deg);
}

.page-content.ptr-content {
	background-color: var(--f7-page-bg-color);
}

.redButton {
	text-align: center !important;
	background-color: red;
	margin: 16px !important;
}

p {
	overflow-wrap: break-word;
	white-space: normal;
}

.toolbar-top {
	top: 0 !important;
}

.actions-button-text {
	border-radius: 28px;
	text-align: center;
}

.actions-label {
	display: block;
	text-align: center;
}

.greenButton {
	text-align: center !important;
	background-color: teal;
	margin: 16px !important;
}

.actions-modal.modal-in {
	border-radius: 18px 18px 0px 0px;
}

.leaflet-container {
	height: 100%;
	width: 100%;
}

$react-rater-link: #ccc !default; // color of star not rated
$react-rater-hover: #666 !default; // color of star on hover
$react-rater-active: #000 !default; // color of star rated

.animated-rater {
	transform: translateZ(0);

	.react-rater > * {
		margin-left: 1rem;
	}

	svg {
		cursor: pointer;
	}
}

.react-rater-star {
	font-size: 35px;
}

.react-rater-star.is-active {
	color: var(--f7-theme-color);
}

.react-rater-star.will-be-active {
	color: var(--f7-theme-color);
}

.react-rater-star.is-active-half::before {
	color: var(--f7-theme-color);
}

.resize-handler:after {
	max-width: 90vh !important;
}

.timeline-item-divider {
	background: var(--f7-theme-color) !important;
}

.list .item-inner {
	padding: 0px !important;
}

.md .list .item-media {
	min-width: 0px !important;
	padding: 0px;
}

@media only screen and (max-width: 768px) {
	.topImage {
		max-height: 150px !important;
	}

	.bottomForm {
		height: calc(100% - 150px) !important;
		overflow: hidden;
	}

	#userImageEditable {
		height: 110px !important;
		width: 110px !important;
	}

	#userFormInside {
		height: 100% !important;
	}

	#loginFormInside {
		height: 100% !important;
	}
}

.smart-select .item-after {
	right: 30px;
}

.list.inset li.swipeout:first-child:last-child,
.list.inset li:first-child:last-child > a {
	border-radius: 2px;
}

.item-input-outline i.icon.material-icons,
.roundedOutlinedSmartSelect .item-link.smart-select i.icon.material-icons {
	position: absolute;
	// left: 10px;
	// bottom: 6px;
	left: 6px;
	bottom: 5px;
}

.input-outline input,
.input-outline select,
.input-outline textarea,
.item-input-outline input,
.item-input-outline select,
.item-input-outline textarea,
.list .item-input-outline input,
.list .item-input-outline select,
.list .item-input-outline textarea {
	padding-left: 35px;
}

.iconNemodificat {
	position: relative !important;
	left: 0px !important;
}

.list .item-content {
	padding-left: 0px;
	padding-right: 0px;

	i {
		bottom: 3px;
	}
}

.button {
	i {
		margin-right: 5px;
		bottom: 3px;
	}
}

#cardSide .card-content {
	background: url("./imagineLogin.webp") var(--f7-theme-color);
	background-size: cover !important;
	background-position: center;
	background-blend-mode: luminosity;
	height: 100px;
}

#cardSettings .card-content {
	background: url("./imagineLogin.webp") var(--f7-theme-color);
	background-size: cover !important;
	background-position: center;
	background-blend-mode: luminosity;
	height: 100px;
}

.CardTop {
	background: linear-gradient(
		30deg,
		var(--f7-theme-color),
		var(--f7-theme-color)
	);
	border-radius: 28px 28px 2px 2px !important;
}

.list .item-floating-label {
	width: fit-content;
	transform: none !important;
	top: 6px;
	color: #808080b3 !important;
}

.cardForm {
	width: 100%;
	padding: 8px;
	height: auto;
	overflow: auto;
	display: grid;
	align-items: center;
	max-height: 100% !important;
}

.bottomForm {
	height: 100%;
}

.imagineCover {
	border-radius: 20px;
	filter: opacity(0.9);
	position: absolute;
	width: 100%;
	height: 100%;
	background-blend-mode: soft-light;
	background-color: var(--f7-theme-color);
	background-size: cover !important;
}

.marker-pin {
	width: 35px;
	height: 35px;
	border-radius: 50% 50% 50% 0;
	background: #c30b82;
	position: absolute;
	transform: rotate(-45deg);
	left: 50%;
	top: 50%;
	margin: -15px 0 0 -15px;
}

.marker-pin::after {
	content: "";
	width: 28px;
	height: 28px;
	margin: 3px 0px 0px -14px;
	background: #fff;
	position: absolute;
	border-radius: 50%;
}

.custom-div-icon img {
	position: absolute;
	left: 0;
	right: 0;
	margin: 11px 10px;
	text-align: center;
}

.custom-div-icon i.awesome {
	margin: 12px auto;
	font-size: 17px;
}

.list li {
	list-style: none;
}

.list {
	margin: 8px 0px !important;
}

tbody tr.hiddenRow {
	background-color: transparent !important;
}

tbody tr.hiddenRow > td {
	padding: 0px 0px 0px 0px !important;
}

// tbody tr td {
// 	background-color: transparent;
// }

.device-desktop .data-table tbody > tr:nth-child(odd) {
	background-color: #7b7b7b14;
}

.device-desktop .data-table tbody > tr:nth-child(even) {
	background-color: transparent;
}

.data-table .sortable-cell.numeric-cell.input-cell > .table-head-label:before,
.data-table .sortable-cell.numeric-cell:not(.input-cell):before,
.data-table
	.sortable-cell:not(.numeric-cell).input-cell
	> .table-head-label:after,
.data-table .sortable-cell:not(.numeric-cell):not(.input-cell):after {
	position: absolute;
	top: 3px;
	right: 10px;
}

/* Test pentru Smart Select */
.roundedOutlinedSmartSelect .item-link.smart-select .item-title {
	position: absolute;
	top: 4px;
	left: 30px;
	font-size: var(--f7-label-font-size);
	font-weight: var(--f7-label-font-weight);
	color: var(--f7-input-placeholder-color);
	background: var(--f7-list-bg-color);
	padding: 0 4px;
	z-index: 10;
	transition-duration: 0.2s;
}

.roundedOutlinedSmartSelect .item-link.smart-select:focus .item-title,
.roundedOutlinedSmartSelect .item-link.smart-select:active .item-title,
.roundedOutlinedSmartSelect .item-link.smart-select:visited .item-title {
	color: var(--f7-label-focused-text-color, var(--f7-theme-color));
}

.roundedOutlinedSmartSelect .item-link.smart-select .item-after {
	right: unset;
	width: 100%;
	text-align: left;
	padding-left: 35px;
	padding-right: var(--f7-input-outline-padding-horizontal);
	flex-shrink: unset;
	box-sizing: border-box;
	height: 40px;
	top: 4px;
	line-height: 40px;
	max-width: unset;
	text-overflow: unset;
	color: var(--f7-input-text-color);
	font-size: var(--f7-input-font-size);
}

.roundedOutlinedSmartSelect .item-link.smart-select .item-after:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border: 1px solid var(--f7-input-outline-border-color) 0px 0px 0px;
	transition-duration: 0.2s;
	pointer-events: none;
}

.roundedOutlinedSmartSelect .item-link.smart-select.active-state {
	background-color: transparent !important;
}

// .roundedOutlinedSmartSelect .item-link.smart-select.active-state .item-after {
//   background-color: var(--f7-list-link-pressed-bg-color);
// }

.roundedOutlinedSmartSelect .item-link.smart-select .ripple-wave {
	display: none;
}

.roundedOutlinedSmartSelect .item-link.smart-select:focus .item-after:after,
.roundedOutlinedSmartSelect .item-link.smart-select:active .item-after:after,
.roundedOutlinedSmartSelect .item-link.smart-select:visited .item-after:after {
	border-width: 2px;
	border-color: var(
		--f7-input-outline-focused-border-color,
		var(--f7-theme-color)
	);
}

.list .item-input .icon {
	top: 4px;
}

.md .input:not(.input-outline):after,
.md .item-input:not(.item-input-outline) .item-input-wrap:after {
	display: none;
}

.list .item-input:after {
	content: "";
	position: absolute;
	background-color: var(--f7-list-item-border-color);
	display: block;
	z-index: 15;
	top: auto;
	right: auto;
	bottom: 0;
	left: 0;
	height: 1px;
	width: 100%;
}

.roundedOutlinedSmartSelect .item-link.smart-select .item-inner::before {
	content: "";
	width: 0;
	height: 0;
	transform: translateY(4px);
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 6px solid var(--f7-input-placeholder-color);
	transition-duration: 0.2s;
}

.roundedOutlinedSmartSelect .item-link.smart-select:focus .item-inner::before,
.roundedOutlinedSmartSelect .item-link.smart-select:active .item-inner::before,
.roundedOutlinedSmartSelect
	.item-link.smart-select:visited
	.item-inner::before {
	border-left-color: var(
		--f7-input-outline-focused-border-color,
		var(--f7-theme-color)
	);
}

/* Test pentru Checkbox */
.simpleInlineCheckbox .item-checkbox {
	height: 40px;
	min-height: 40px;
	margin-top: 8px;
	border: 1px solid var(--f7-input-outline-border-color) 0px 0px 0px;
}

.simpleInlineCheckbox .item-checkbox i.icon-checkbox {
	left: 3px;
	margin-right: 15px;
	bottom: unset;
}

.simpleInlineCheckbox .item-checkbox .item-inner {
	align-self: unset;
}

/* Test Shadow Card */
// .card {
//   box-shadow: -4px -4px 8px 0px #eceaea, 4px 4px 8px 0px #464646;
// }

tbody label.item-checkbox {
	top: -7px;
	left: 5px;
}

.rbc-toolbar {
	flex-wrap: nowrap !important;
	margin-bottom: 5px !important;
}

.rbc-toolbar .rbc-btn-group button {
	width: auto;
	cursor: pointer;
}

#gantt {
	height: 600px;
}

.toolbar {
	height: 38px;
}

.hideControlsCalendar .rbc-toolbar span.rbc-btn-group {
	display: none;
}

.rct-scroll {
	width: 100% !important;
}

.cardContentFloatingTitle {
	position: absolute;
	top: -10px;
	left: 17px;
	color: var(--f7-text-color);
	font-weight: bold;
	background-color: var(--f7-card-bg-color);
	font-size: 14px;
	padding: 0px 6px;
}

.cardTopSpaceFT {
	margin-top: 15px;
}

.popoverulMeu.popover .list:last-child li:last-child,
.popoverulMeu.popover .list:last-child li:last-child a,
.popoverulMeu.popover .list:last-child li:last-child > label,
.popoverNotificari.popover .list:last-child li:last-child,
.popoverNotificari.popover .list:last-child li:last-child a,
.popoverNotificari.popover .list:last-child li:last-child > label {
	border-radius: 0;
}

.breadcrumbs div {
	position: relative;
	font-size: 12px;
	padding: 9px 6px;
	text-transform: capitalize;
	display: inline-block;
}

.breadcrumbs div:last-child {
	background-color: white;
	color: var(--f7-theme-color);
	padding-left: 12px;
	font-weight: bold;
}

.breadcrumbs div:before {
	position: absolute;
	content: ">";
	top: 50%;
	left: -3px;
	transform: translateY(-50%);
}

.breadcrumbs div:first-child:before {
	content: "";
	left: -7px;
}

.breadcrumbs div:last-child:before {
	content: "";
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid var(--f7-theme-color);
	left: 0;
}

.breadcrumbs div:last-child:after {
	content: "";
	position: absolute;
	left: 100%;
	top: 50%;
	transform: translateY(-50%);
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
}

.md .navbar .navbar-inner .searchbar-expandable {
	width: 0px;
	left: auto;
	right: 50px;
}

.md .navbar .navbar-inner .searchbar-expandable.searchbar-enabled {
	width: 250px;
	left: auto;
	right: 50px;
}

.md
	.navbar
	.navbar-inner
	.searchbar.searchbar-expandable
	.searchbar-inner
	.searchbar-input-wrap
	input {
	padding-left: 46px;
	padding-right: 40px;
}

.checkbox i,
.icon-checkbox,
.card,
.button,
.segmented,
.segmented .button:last-child,
.segmented .button:first-child,
.segmented .button:first-child:last-child {
	border-radius: 0;
}

label.item-checkbox > .icon-checkbox {
	margin-right: 10px;
}

.toolbar {
	height: 28px;
	background-color: transparent;
}

.toolbar .toolbar-inner {
	padding-left: 14px;
	padding-right: 14px;
}

.toolbar a.link.tab-link {
	background-color: var(--f7-theme-color); //#35ab95;
	overflow: unset;
}

.toolbar a.link.tab-link.tab-link-active {
	background: var(--f7-toolbar-bg-color, var(--f7-bars-bg-color)); //#149980;
	z-index: 2;
	filter: drop-shadow(0px 0px 2px black);
}

.toolbar a.link.tab-link:before,
.toolbar a.link.tab-link:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	top: 0;
	left: -14px;
	border-right: 7px solid var(--f7-theme-color); // #35ab95;
	border-bottom: 19px solid var(--f7-theme-color); // #35ab95;
	border-top: 19px solid transparent;
	border-left: 7px solid transparent;
	opacity: 1;
}

.toolbar a.link.tab-link:after {
	left: auto;
	right: -14px;
	border-left: 7px solid var(--f7-theme-color); // #35ab95;
	border-right: 7px solid transparent;
}

.toolbar a.link.tab-link.tab-link-active:before {
	border-right-color: var(
		--f7-toolbar-bg-color,
		var(--f7-bars-bg-color)
	); // #149980;
	border-bottom-color: var(
		--f7-toolbar-bg-color,
		var(--f7-bars-bg-color)
	); // #149980;
}

.toolbar a.link.tab-link.tab-link-active:after {
	border-bottom-color: var(
		--f7-toolbar-bg-color,
		var(--f7-bars-bg-color)
	); // #149980;
	border-left-color: var(
		--f7-toolbar-bg-color,
		var(--f7-bars-bg-color)
	); // #149980;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	color: var(--f7-text-color) !important;
	-webkit-text-fill-color: var(--f7-text-color) !important;
	transition: background-color 5000s ease-in-out 0s;
	background-color: transparent !important;
}

.butoaneContextDatagrid {
	z-index: 1;
	transition: all 350ms ease-in-out;
}

.butoaneContextDatagrid.inchis {
	width: calc(100% - 320px) !important;
}

.optiuniPlanificare.list .item-content,
.optiuniPlanificare.list .item-inner,
.optiuniActivitate.list .item-content,
.optiuniActivitate.list .item-inner {
	min-height: initial;
	margin: 0;
	height: 34px;
}

.optiuniActivitate.list
	.roundedOutlinedSmartSelect
	.item-link.smart-select
	.item-title,
.optiuniPlanificare.list
	.roundedOutlinedSmartSelect
	.item-link.smart-select
	.item-title {
	line-height: var(--f7-label-font-size);
	top: 1px;
}

.optiuniActivitate.list
	.roundedOutlinedSmartSelect
	.item-link.smart-select
	.item-after,
.optiuniPlanificare.list
	.roundedOutlinedSmartSelect
	.item-link.smart-select
	.item-after {
	height: 34px;
	line-height: 34px;
}

.optiuniActivitate.list .item-title.item-label.item-floating-label,
.optiuniPlanificare.list .item-title.item-label.item-floating-label {
	line-height: var(--f7-label-font-size);
	position: absolute;
	top: 0px;
	left: 0;
}

.fab-buttons a,
.fab > a {
	height: 35px;
	width: 35px;
}

.filter-bar-treelist {
	overflow: hidden;
	background-color: var(--f7-theme-color);
	position: absolute;
	height: 35px;
	// bottom: calc(var(--f7-fab-margin) + var(--f7-safe-area-bottom));
	top: 16px;
	right: calc(var(--f7-fab-margin) + var(--f7-safe-area-right));
	z-index: 1499;
	border-radius: calc(var(--f7-fab-size) / 2) 0 0 calc(var(--f7-fab-size) / 2);
	transform: translateX(-17px);
	transition: all 350ms ease-in-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

.fabToateFiltrate a {
	background-color: red;
}

.md .filter-bar-treelist .checkbox i,
.md .filter-bar-treelist .icon-checkbox {
	margin-top: 1px;
	margin-right: 5px;
}

.filter-bar-treelist .checkbox,
.filter-bar-treelist label.item-checkbox {
	color: white;
}

.innerAppbar {
	display: inline-flex;
	width: 100%;
	height: 34px;
	align-items: center;
	justify-content: space-between;
}

.titleTopBar {
	font-weight: bold;
	color: white;
	font-size: 17px;
}

.appBarTopBar {
	width: 100%;
	background-color: var(--f7-theme-color);
}

.navLeftTopBar {
	display: flex;
	align-items: center;
}

.paddingGrafice {
	padding-top: 28px;
}

.sheet-modal {
	min-height: 50vh;

	.toolbar {
		height: 48px;

		.sheet-close {
			color: var(--f7-theme-color);
			font-weight: bold;
		}
	}
}

.stickyGrafic {
	z-index: 100;
	position: fixed;
}

.react-calendar-timeline .rct-header-root {
	background: var(--f7-theme-color) !important;
}

.react-calendar-timeline .rct-calendar-header {
	border: 0px !important;
}

.rct-hl {
	//border-color:var(--f7-theme-color)!important;
	border-top: 1px solid var(--f7-theme-color) !important;
	border-bottom: none !important;
}

.react-calendar-timeline .rct-scroll > div > div {
	top: 1px !important;
}

.treeViewStanga .icon {
	color: white !important;
}

.treeViewStanga .treeview-toggle::after {
	border-left-color: white !important;
}

.SheetDinamic .PaginaGraficSalariat {
	position: relative;
	margin-top: 6px;
}

.list input[type="datetime-local"] {
	max-width: inherit;
}

.columnSelector :hover {
	border-right: 2px solid red;
}

.popoverSelectBoxTop li,
.popoverSelectBoxTop label,
.popoverSelectBoxTop .item-inner {
	height: 33px !important;
	min-height: 33px !important;
}

#ListaChat .item-content,
#ListaChat .item-inner {
	height: 33px !important;
	min-height: 33px !important;
}

#CheckboxuriLevel .icon-checkbox {
	top: 17px;
}

.datagridStatus {
	width: 12px;
	height: 12px;
	border-radius: 100%;
	background-color: transparent;
	margin: 0 auto;
	margin-top: 3px;
	border: 4px solid var(--f7-theme-color);
}

#listSalariatiTotalTaskuri {
	margin: 0px !important;
}

.notificationEroare {
	border-left: 5px solid red;

	.notification-title {
		color: red !important;
	}
}

.notificationSuccess {
	border-left: 5px solid green;

	.notification-title {
		color: green !important;
	}
}

.navbar .title-large-text {
	text-align: center;
}

.md .stepper-button,
.md .stepper-button-minus,
.md .stepper-button-plus {
	width: 25px;
}

.dottedForCopy {
	background-color: palevioletred !important;
}

#UserTreeview .treeview-item-content:first-child {
	margin: 0px;
}

.messagebar .toolbar {
	height: 100%;
}

.messages {
	padding-bottom: 77px;
}

.messagebar .toolbar-inner {
	height: 100%;
}

.md .messagebar-area {
	height: 100%;
	margin-top: 26px;
	margin-bottom: 8px;
	padding-right: 36px;
}

[name="mesajWrapperName"] {
	height: 100%;
}
//DEVEXTREME
.dx-toolbar .dx-toolbar-items-container {
	height: 30px !important;
}

.dx-overlay-wrapper {
	z-index: 100000000000000 !important;
}

.dx-treelist-container {
	color: var(--f7-actions-button-text-color) !important;
	background-color: var(--f7-block-strong-bg-color) !important;
}

.dx-treelist-headers.dx-treelist-nowrap {
	color: var(--f7-actions-button-text-color) !important;
	background-color: var(--f7-block-strong-bg-color) !important;
	// border-radius: 16px 16px 0px 0px;
	border-color: var(--f7-input-outline-border-color) !important;
}

.dx-treelist-rowsview.dx-treelist-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
	border-color: var(--f7-input-outline-border-color) !important;
}

td.dx-treelist-cell-expandable {
	border-color: var(--f7-input-outline-border-color) !important;
}

.dx-treelist-headers .dx-treelist-table .dx-row > td {
	border-color: var(--f7-input-outline-border-color) !important;
}

#tl .dx-treelist-rowsview .dx-selection.dx-row > td,
.dx-treelist-rowsview .dx-selection.dx-row > td,
.dx-treelist-rowsview .dx-selection.dx-row > tr > td,
.dx-treelist-rowsview .dx-selection.dx-row > tr > td {
	background-color: #92cbe8 !important;
}

.dx-treelist .dx-row > td {
	border: 1px solid lightgray;
}

// .dx-toolbar-after {
//     width: 100%;
// }

.dx-treelist-search-panel {
	width: 60% !important;
	margin: 0px !important;
	right: 0px;
	top: 0px;
	float: right;
}

/*#listaTipSarcini .item-content:after {
    display: none;
}*/

/*.dx-treelist .dx-row > td {
    padding: 2px !important;
}
.dx-row{
    padding:2px!important;
}
*/
.dx-widget {
	font-family: Arial, sans-serif;
	font-size: 10px !important;
}

#Tree .dx-treelist-header-panel {
	position: sticky;
	top: -8px;
	z-index: 10;
	background-color: white;
}

#Tree .dx-toolbar .dx-toolbar-after {
	padding: 0px;
}

.dx-treelist-header-panel {
	border-bottom: 0px !important;
}

.dx-treelist-borders > .dx-treelist-filter-panel,
.dx-treelist-borders > .dx-treelist-headers,
.dx-treelist-borders > .dx-treelist-pager {
	border-top: 0px;
}

.dx-treelist-header-panel {
	border-bottom: 0px;
}

.dx-treelist-select-all .dx-checkbox {
	top: 0px;
}

.item-input-wrap > input[type="file"] {
	margin-top: 10px;
}

.toolbar .toolbar-inner {
	padding: 0px;
}

.message-name {
	display: block;
}

.message:not(.message-first) .message-name {
	display: none;
}

.message-text-footer {
	text-align: right;
}

#CheckboxAlocareTaskuriSalariati label.checkbox i.icon-checkbox {
	margin: 0 auto;
}

/*.my-modal-custom-class {
    z-index: 10000;
}*/

.flexible-modal {
	position: absolute;
	z-index: 10000;
	border: 1px solid #ccc;
	background: white;
	overflow: hidden;
}

.flexible-modal-mask {
	position: fixed;
	height: 100%;
	background: rgba(55, 55, 55, 0.6);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.flexible-modal-resizer {
	position: absolute;
	right: 0;
	bottom: 0;
	cursor: se-resize;
	margin: 5px;
	border-bottom: solid 2px #333;
	border-right: solid 2px #333;
	z-index: 1;
}

.flexible-modal-drag-area {
	width: auto !important;
	//background: rgba(22, 22, 333, 0.2);
	background: transparent;
	height: 34px;
	position: absolute;
	left: 80px;
	right: 80px;
	top: 0;
	cursor: move;
	z-index: 1;
}

.searchbar input[type="search"],
.searchbar input[type="text"] {
	padding: 0px 0px 0px 28px;
}

.rangeDepth {
	position: absolute;
	top: 0px;
	left: 10px;
	width: 30% !important;
	z-index: 100000;
}

#colIntretinereProiecteStanga {
	.rangeDepth {
		left: 50px;
	}
}

.audio-react-recorder {
	width: 100px;
	display: inline-block;
}

#mesajInputWrapper {
	.audio-react-recorder {
		display: none;
	}
}

.spanOreLucreate {
	width: 100px;
	display: inline-block;
}

.popupDatagrid {
	.list {
		margin: 0px !important;
	}
}

#estimatLucratSalariatiIntervalList {
	margin: 0 !important;
}

#PaginaMesaje {
	.page-content {
		padding-bottom: 0px !important;
	}

	.list {
		.item-title {
			font-size: 13px;
		}
	}
}

.messagebar textarea {
	max-height: 250px !important;
}

.message-bubble {
	min-width: 150px;
	min-height: 50px;

	.button {
		.icon {
			margin: 0px;
			bottom: 11px;
			left: 5px;
		}
	}
}

.message-text {
	padding-right: 15px;
	padding-bottom: 15px;
}

#container-horizontal {
	display: inline-flex;
	height: 60px;

	.item {
		width: 40px;
	}
}

.aurora .segmented-strong-aurora .button.button-active,
.aurora .segmented-strong-aurora .button.tab-link-active,
.ios .segmented-strong-ios .button.button-active,
.ios .segmented-strong-ios .button.tab-link-active,
.md .segmented-strong-md .button.button-active,
.md .segmented-strong-md .button.tab-link-active,
.segmented-strong .button.button-active,
.segmented-strong .button.tab-link-active {
	--f7-button-bg-color: var(--f7-theme-color);
}

.dx-treelist-content .dx-treelist-table .dx-row > td:first-child,
.dx-treelist-content .dx-treelist-table .dx-row > tr > td:first-child {
	padding: 2px;
}

.message-text-footer {
	bottom: 0px;
	right: 0px;
	position: absolute;
}

// .testColoana {
// 	background-color:red;
// }

.singleMyVideo:hover .singleCallButtonsWrapper,
.multiMyVideo:hover .multiCallButtonsWrapper,
.multiOtherVideo:hover .multiCallButtonsWrapper {
	transform: translateY(0) !important;
}

.singleCallButtonsWrapper a.button:last-child:hover i,
.multiMyVideo .multiCallButtonsWrapper a.button:last-child:hover i {
	color: #ff3b30;
}

@keyframes pulse {
	0% {
		filter: drop-shadow(0 0 0px var(--f7-theme-color));
	}

	70% {
		filter: drop-shadow(0 0 12px var(--f7-theme-color));
	}

	100% {
		filter: drop-shadow(0 0 0px var(--f7-theme-color));
	}
}

.numeTaskChat {
	font-size: 12px;
	color: black;
	position: absolute;
	right: 33px;
	top: 0px;
}

textarea {
	margin-top: 10px;
}

.panel {
	min-width: 350px;
}

.list input[type="date"],
.list input[type="datetime-local"],
.list input[type="email"],
.list input[type="month"],
.list input[type="number"],
.list input[type="password"],
.list input[type="search"],
.list input[type="tel"],
.list input[type="text"],
.list input[type="time"],
.list input[type="url"],
.list select {
	padding-bottom: 4px;
}

#ListaEstimatLucratClienti ul {
	display: flex;
}

.rbc-overlay {
	z-index: 1000 !important;
}

#ListEstimatLucratTaskuri {
	margin: 0px !important;
}

//LEGENDE

.arhivat {
	font-style: italic;
	color: gray !important;
	background-image: url("honeycomb_bg.webp") !important;
	background-size: 250px !important;
}

.ascuns {
	color: #9a9595 !important;
	font-weight: bold;
	background-color: transparent;
}

.tasknormal {
	color: black;
	background-color: transparent;
}

.taskDeadlineDepasit {
	color: red !important;
	font-weight: bold;
	background-color: transparent;
}

.taskactiv {
	background-color: #a4ff7d !important;
	color: black;
}

.taskinchis {
	background-color: paleturquoise !important;
	color: black;
}

.taskacceptat {
	background-color: #ffd800 !important;
	color: black;
}

.taskpropus {
	background-color: chocolate !important;
	color: black;
}

.taskoprit {
	color: forestgreen;
	font-weight: bold;
	background-color: transparent;
}

.proiect {
	color: #1785a3;
	background-color: transparent;
	font-weight: bold;
}

.subproiect {
	color: #24bf89;
	background-color: transparent;
	font-weight: bold;
}

.firma {
	color: #8f57ad;
	background-color: transparent;
	font-weight: bold;
}

.contract {
	color: #c259b4;
	background-color: transparent;
	font-weight: bold;
}

.modul {
	color: #cc7904;
	background-color: transparent;
	font-weight: bold;
}

.task {
	background: linear-gradient(90deg, white, #c94726);
	color: black;
	font-weight: bold;
}

.range-knob {
	z-index: 100;
}

.range-knob-active-state {
	z-index: 100;
	top: 30px;
}

.aurora .segmented-strong-aurora .button.button-active,
.aurora .segmented-strong-aurora .button.tab-link-active,
.ios .segmented-strong-ios .button.button-active,
.ios .segmented-strong-ios .button.tab-link-active,
.md .segmented-strong-md .button.button-active,
.md .segmented-strong-md .button.tab-link-active,
.segmented-strong .button.button-active,
.segmented-strong .button.tab-link-active {
	background-color: var(--f7-theme-color-tint);
}

//FIXES

.dx-numberbox input[type="number"] {
	appearance: textfield;
}
.ios .dialog-button {
	flex: 1;
}
.dx-scrollable-native {
	scroll-snap-type: proximity;
}
.swiper-scrollbar {
	touch-action: none;
}

/* Fix styles for gmail popup */
#gmailPopup .button i {
	bottom: unset;
}
/* END - Fix styles for gmail popup */

/* Gmail item list in side panel */
.gmailItemList {
	padding: 12px 8px;
	box-shadow: 0 0 0px 0px #919191;
	cursor: pointer;
	transition: all 0.35s ease-in-out;
}

.gmailItemList:hover,
.gmailItemList.gmailSelected {
	z-index: 25;
	box-shadow: 0 0 8px 0px #919191;
}
/* END - Gmail item list in side panel */

/* Popup label for gmail side bar buttons */
.gmailSideTab {
	position: relative;
	overflow: unset;
}

.gmailSideTab.activeGmailSideTab:after {
	content: "";
	position: absolute;
	width: 2px;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #007aff;
	z-index: -1;
}

.gmailSideTab > div {
	position: absolute;
	top: 3px;
	left: calc(100% + 5px);
	line-height: 1;
	background-color: #f7f7f7;
	padding: 10px 13px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	box-shadow: 3px 0px 6px -2px #7c7c7c;
	opacity: 0;
	visibility: hidden;
	transition: all 0.35s ease-in-out;
}

.gmailSideTab:hover > div {
	left: 100%;
	opacity: 1;
	visibility: visible;
}

.gmailSideTab > div:before {
	content: "";
	position: absolute;
	top: 9px;
	left: -9px;
	border-top: 8px solid transparent;
	border-right: 10px solid #f7f7f7;
	border-bottom: 8px solid transparent;
}
/* END - Popup label for gmail side bar buttons */

/* Popup label for gmail content bar buttons */
.gmailContentTab {
	position: relative;
	overflow: unset;
	padding: 0px 2px;
}

.gmailContentTab > div {
	position: absolute;
	top: calc(100% + 5px);
	left: 50%;
	transform: translateX(-50%);
	line-height: 1;
	background-color: #f7f7f7;
	padding: 10px;
	border-radius: 8px;
	filter: drop-shadow(0px 0px 2px #7c7c7c);
	opacity: 0;
	visibility: hidden;
	transition: all 0.35s ease-in-out;
}

.gmailContentTab:last-child > div,
.gmailContentTab:nth-last-child(2) > div {
	left: unset;
	right: 0;
	transform: unset;
}

.gmailContentTab:hover > div {
	top: 100%;
	opacity: 1;
	visibility: visible;
}

.gmailContentTab > div:before {
	content: "";
	position: absolute;
	top: -7px;
	left: 50%;
	transform: translateX(-50%);
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #f7f7f7;
}

.gmailContentTab:last-child > div:before,
.gmailContentTab:nth-last-child(2) > div:before {
	left: unset;
	right: 11px;
	transform: unset;
}

/* Popup label menu for gmail content bar buttons */
.gmailContentTab .gmailContentTabLabelMenu > a:hover {
	background-color: #f1f1f1;
}
/* END - Popup label menu for gmail content bar buttons */
/* END - Popup label for gmail content bar buttons */

#ListaLogariCurente {
	margin: 0px !important;
	ul {
		display: flex;
	}
}
label.item-content.item-checkbox {
	height: 52px !important;
}


@keyframes noteAnimation {
  0%   { filter: grayscale(0); }
  50%  { filter: grayscale(1); }
  100% { filter: grayscale(0); }
}

.timelineItem {
	filter: grayscale(0);

	&.withNote {
		animation: noteAnimation 5s ease-in-out infinite;
	}
}

input[type=date][id^=dx],
input[type=datetime-local][id^=dx],
input[type=email][id^=dx],
input[type=month][id^=dx],
input[type=number][id^=dx],
input[type=password][id^=dx],
input[type=search][id^=dx],
input[type=tel][id^=dx],
input[type=text][id^=dx],
input[type=time][id^=dx],
input[type=url][id^=dx],
select[id^=dx],
textarea[id^=dx] {
	padding: 4px 5px;
	font-size: 12px;
}

input[type=date][id^=mui],
input[type=datetime-local][id^=mui],
input[type=email][id^=mui],
input[type=month][id^=mui],
input[type=number][id^=mui],
input[type=password][id^=mui],
input[type=search][id^=mui],
input[type=tel][id^=mui],
input[type=text][id^=mui],
input[type=time][id^=mui],
input[type=url][id^=mui],
select[id^=mui],
textarea[id^=mui] {
	padding: 5px 14px;
	// padding: 8.5px 14px;
	font-size: 14px;
	box-sizing: content-box;
}

#fileManagerAtasamente.dx-filemanager .dx-drawer-panel-content.dx-drawer-panel-content-initial {
	display: none;
}
