.datagridIcon {
    color: var(--f7-theme-color);
    margin: 0px 2px !important;
}

.datagridBtnTop {
    width: 40px;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.selectBoxItems {
    white-space: nowrap;
    margin: 0
}

.datagridTr:hover {
    background-color: rgba(var(--f7-theme-color-rgb),0.2) !important;
}

.datagridTd {
    text-overflow: ellipsis;
    max-width: 63px;
    text-align: left;
    padding: 0px 6px !important;
    overflow: hidden;
    border-right: 1px solid var(--f7-table-cell-border-color);
}

.datagridTh {
    padding: 0px 6px !important;
    // max-width: 63px !important;
    display: table-cell;
    text-align: center !important;
    border-right: 1px solid var(--f7-table-cell-border-color);
    position: sticky;
    top: 0px;
    background-color: var(--f7-actions-bg-color);
}

.datagridOptionsTd {
    padding: 0px !important;
    max-width: 63px !important;
    text-align: center !important;
    border-right: 1px solid var(--f7-table-cell-border-color);
}

.datagridOptionsTh {
    padding: 0px !important;
    max-width: 63px !important;
    text-align: center !important;
    border-right: 1px solid var(--f7-table-cell-border-color);
    position: sticky;
    top: 0px;
    background-color: var(--f7-actions-bg-color);
}

/*.data-table table, table.data-table {
    border: 1px solid var(--f7-table-cell-border-color);
}*/
.card-header:after {
    display: none;
}

.data-table table, table.data-table {
    table-layout: fixed;
    width: auto;
}

.datagridBtnOptions {
    width: 40px;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
    height: var(--f7-table-body-cell-height);
}

.trActive {
    box-shadow: inset 0px 0px 0px 3px var(--f7-theme-color-tint);
    filter: brightness(1.3);
}

.tdActive {
    box-shadow: inset 0px 0px 0px 3px var(--f7-theme-color-tint);
}

.theadVizibil {
    visibility: visible;
    position: sticky;
    background-color: var(--f7-actions-bg-color);
    top: 0px;
    //display: table-caption;
    z-index: 10000000;
}

.popoverTitle {
    background-color: var(--f7-actions-bg-color);
    position: absolute;
    top: -16px;
    padding: 0px 6px;
}

.isDatagridChild td:last-child {
    border-right: 0px;
    border-right-style: hidden;
}

.data-table table {
    border-right-style: hidden;
}

.data-table .sortable-cell.sortable-neutral:after, .data-table .sortable-cell.sortable-neutral:before, .data-table .table-head-label:after, .data-table .table-head-label:before {
    transform: rotate( 90deg ) !important;
}

.datagridMain {
}

.data-table tbody td:before, .data-table tbody th:before {
    z-index: 0;
}